<template>
    <div>

        <!-- MAIN PAGE -->
        <b-card>

            <!-- HEADER -->
            <div slot="header" style="height: 100%; width: 100%">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <h5 style="margin-bottom: 0"><b>Exports</b></h5>
                    </div>
                    <div class="d-flex align-items-center" style="gap: 8px">
                        <b-input-group size="sm" prepend="Tenant">
                            <b-form-select v-if="tenants.length > 1" v-model="curTenant"
                                           :options="tenantOptions" size="sm"/>
                            <b-input-group-append v-else>
                                <b-input-group-text style="background-color: #333333">
                                    {{ tenants.length === 1 ? tenants[0].tenantName : "no tenants" }}
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
            </div>

            <!-- TABLE -->
            <v-client-table ref="dTable" style="width: 100%" :columns="table.columns"
                            :data="exports" :options="table.options" :theme="table.theme" class="dataTable">
                <div slot="h__download/delete" style="float: right">{{canDeleteExports === true ? 'Download/Delete' : 'Download'}}</div>
                <div slot="exportedOn" slot-scope="props">
                    {{props.row.exportedOn.toLocaleString()}}
                </div>
                <div slot="fileSizeBytes" slot-scope="props">
                    {{convertFileSize(props.row.fileSizeBytes)}}
                </div>
                <div slot="download/delete" slot-scope="props">
                    <div class="tableButtonContainer" v-if="props.row.downloadReady">
                        <b-button class="tableButton"
                                  @click="downloadFile(props.row.downloadString, props.row.fileKey)">
                            <fa-icon :icon="['fas', 'download']"/>
                        </b-button>
                        <b-button v-if="canDeleteExports" class="tableButton" @click="openDeleteExportModal(props.row)">
                            <fa-icon :icon="['fas', 'trash']"/>
                        </b-button>
                    </div>
                    <div class="d-flex justify-content-end" style="margin: 0 -12px; position: relative"
                         v-if="!props.row.downloadReady">
                        <div style="position: absolute; width: 150px"
                             class="d-flex justify-content-center align-items-center h-100">
                            <div v-if="props.row.jobStage === 'Queued'">Queued</div>
                            <div v-else-if="props.row.percentComplete !== 100">
                                {{ ((props.row.percentComplete / 100) * 100).toFixed(0) }}%
                            </div>
                            <div v-else>Finalizing...</div>
                        </div>
                        <b-progress style="border-radius: 50px; width: 150px" :max="100" animated>
                            <b-progress-bar :value="props.row.percentComplete"/>
                        </b-progress>
                    </div>
                </div>
            </v-client-table>

        </b-card>

        <!-- DELETE EXPORT MODAL -->
        <b-modal no-close-on-backdrop hide-header hide-footer class="modal-dark"
                 v-model="deleteExportModal.modal" v-if="deleteExportModal.modal && deleteExportModal.obj">
            <h5 class="d-flex justify-content-center text-center font-weight-bold">
                Are you sure you want to delete the "{{ deleteExportModal.obj.fileKey }}" export?
            </h5>
            <div class="w-100 d-flex justify-content-center" style="gap: 10px">
                <b-button size="sm" variant="secondary" @click="deleteExportModal.modal = false">Cancel</b-button>
                <b-button size="sm" variant="danger" @click="deleteExport()">Delete</b-button>
            </div>
        </b-modal>

    </div>

</template>

<script>
import {ClientTable} from 'vue-tables-2';
import SmartSuiteArchive from "@/shared/smartsuite_services/smartsuite_archive";
import Vue from 'vue';
import tenantStore from "@/store/tenantStore";
import authStore from "@/store/authStore";

Vue.use(ClientTable);

export default {
    name: "Export",
    data: () => {
        return {
            tenants: [],
            curTenant: -1,
            table: {
                columns: ['exportName', 'deviceStoreId', 'exportedBy', 'exportedOn', 'fileSizeBytes', 'download/delete'],
                options: {
                    orderBy: {column: 'exportedOn', ascending: false},
                    headings: {
                        exportName: 'Export Name',
                        deviceStoreId: 'Device Store Id',
                        exportedBy: 'Exported By',
                        exportedOn: 'Exported On',
                        fileSizeBytes: 'File Size'
                    },
                    sortable: ['exportName', 'deviceStoreId', 'exportedBy', 'exportedOn'],
                    filterable: ['exportName', 'deviceStoreId', 'exportedBy', 'exportedOn'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover'
                },
                theme: 'bootstrap4',
            },
            deleteExportModal: {
                modal: false,
                obj: null
            }
        }
    },
    async created() {
        await this.getTenant();
    },
    beforeDestroy() {
        this.tenants.forEach(tenant => {
            tenant.ssArchive.close();
        });
    },
    methods: {
        getTenant() {
            tenantStore.dispatch('getTenants').then(async response => {
                this.tenants = response;
                if (this.tenants.length === 1) {  // If only one tenant display that one
                    this.curTenant = 0;
                }
                this.tenants.map(async (tenant, index) => {
                    await this.getDevices(index);
                });
            })
        },
        async getDevices(index) {
            // Make Smart Suite Archive
            this.tenants[index].ssArchive = new SmartSuiteArchive(null, null, null, this.tenants[index]);
            await this.tenants[index].ssArchive.connect();
            this.tenants[index].ssArchive.getStorageServers(async response => {
                this.tenants[index].storageServers = response;
                Vue.set(this.tenants[index], 'exports', []);

                // Get Exports
                this.tenants[index].ssArchive.getAllExports(event => {
                    this.tenants[index].exports = this.tenants[index].exports.concat(event.map(_export => {
                        this.updateExportData(_export, this.tenants[index]._id, index);
                        return _export;
                    }));
                });

                // Make Export Update Listener
                await this.tenants[index].ssArchive.exportUpdateListener(async event => {
                    if (event.downloadReady) {
                        this.tenants[index].ssArchive.getExportPageExports(event.storageServer, event2 => {
                            this.tenants[index].exports = this.tenants[index].exports.filter(_export => {
                               if (_export.storageServer !== event.storageServer && _export.storageServer) {
                                   return _export;
                               }
                            }).concat(event2.map(_export => {
                                this.updateExportData(_export, this.tenants[index]._id, index, event.storageServer)
                                return _export;
                            }));
                        });
                    } else {
                        this.updateExportData(event, this.tenants[index]._id, index)
                        let i = this.tenants[index].exports.findIndex(_export => {
                            return _export.jobId === event.jobId;
                        });
                        if (i !== -1) {
                            Vue.set(this.tenants[index].exports, i, event);
                        } else {
                            this.tenants[index].exports.push(event);
                        }
                    }
                });

                // Make Export Updates Listener
                await this.tenants[index].ssArchive.exportUpdatesListener(exportList => {
                    //assume event is a list of remaining events from a storage server
                    if (exportList.length !== 0) {
                        let storageServer = exportList[0].storageServer;
                        this.tenants[index].exports = this.tenants[index].exports.filter(_export => {
                            //leave all events that aren't from the storage server in question
                            if (_export.storageServer !== storageServer) {
                                return _export;
                            }
                            //return all events with the same jobid as one inside the event list,
                            //this should isolate the one that was deleted
                            let duplicate = exportList.find(event => event.jobId === _export.jobId);
                            if (duplicate !== undefined) {
                                return _export;
                            }
                        });
                    }
                });

            });
        },
        openDeleteExportModal(obj) {
            this.deleteExportModal.obj = obj;
            this.deleteExportModal.modal = true;
        },
        convertFileSize(size) {
              if (size < 1000) {
                  return size + 'B';
              } else if (size >= 1000 && size < 1000000) {
                  return Math.round(size/1000) + ' KB';
              } else if (size >= 1000000 && size < 1000000000) {
                  return Math.round(size/1000000) + ' MB';
              } else if (size >= 1000000000 && size < 1000000000000) {
                  return Math.round(size/1000000000) + ' GB';
              } else if (size >= 1000000000000 && size < 1000000000000000) {
                  return Math.round(size/1000000000000) + ' TB';
              } else {
                  return 'Unknown Size';
              }
        },
        updateExportData(_export, tenantId, tenantIndex) {
            _export.tenantIndex = tenantIndex;
            _export.tenant = tenantId;
            if (_export.metaData && _export.metaData['x-amz-meta-userid']) {
                _export.exportedOn = new Date(_export.metaData['x-amz-meta-deliveredat']);
                _export.exportedBy = _export.metaData['x-amz-meta-userid'];
            } else {
                _export.exportedOn = 'loading...';
                _export.exportedBy = 'loading...';
            }
            return _export;
        },
        async downloadFile(uri, name) {
            uri = uri + '&access_token=' + authStore.getters.getUserManager.getAccessToken();
            let moduleGrants = await authStore.dispatch('getModuleGrants');
            if (moduleGrants && moduleGrants.includes('archiveexport')) {
                try {
                    let link = document.createElement("a");
                    link.download = name;
                    link.href = uri;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.$mToast({
                        title: 'Your download is on its way.\n' +
                            'Please do not refresh this page.',
                        style: 'success'
                    });
                } catch {
                    this.$mToast({
                        title: 'Something went wrong , please refresh your page and try again.',
                        style: 'error'
                    });
                }
            } else {
                this.$mToast({
                        title: 'You Do Not Have Permission to Download',
                        style: 'error'
                    },
                );
            }
        },
        deleteExport() {
            this.deleteExportModal.modal = false;
            let index = this.tenants[this.deleteExportModal.obj.tenantIndex].exports.findIndex(_export => {
               return _export.jobId === this.deleteExportModal.obj.jobId;
            });
            if (index !== -1) {
                this.tenants[this.deleteExportModal.obj.tenantIndex].exports.splice(index, 1);
            }
            let ssArchive = this.tenants[this.deleteExportModal.obj.tenantIndex].ssArchive;
            ssArchive.deleteExportPageArchiveExport(this.deleteExportModal.obj);
        },
    },
    computed: {
        tenantOptions() {
            let result;
            if (this.tenants.length > 1) {
                result = [{text: 'All Tenants', value: -1}];
            } else {
                result = [];
            }
            return result.concat(this.tenants.map((tenant, index) => {
                return {text: tenant._id, value: index};
            }))
        },
        exports() {
            if (this.tenants.length > 0) {
                if (this.curTenant !== -1) {
                    return this.tenants[this.curTenant].exports || [];
                }
                return this.tenants.reduce((result, tenant) => {
                    if (tenant.exports && Array.isArray(tenant.exports)) {
                        return result.concat(tenant.exports);
                    }
                    return result;
                }, []);
            }
            return [];
        },
        canDeleteExports() {
            if (authStore.getters.getUserRole === 'securityadmin' || authStore.getters.getUserRole === 'poweruser' || (authStore.getters.getFeatureGrants && authStore.getters.getFeatureGrants.includes('deleteexport'))) {
                return true;
            }
            return false;
        },
        getTenantName(tenantId) {
            let tenant = this.tenants.find(tenant => tenant._id === tenantId.toString())
            if (tenant !== undefined) {
                return tenant.tenantName;
            } else {
                return '';
            }
        }
    },
    watch: {
        curTenant: {
            handler() {
                let result = ['exportName', 'deviceStoreId', 'exportedBy', 'exportedOn'];
                if (this.curTenant === -1) {
                    result.unshift('tenant');
                }
                this.table.options.filterable = result;
                this.table.options.sortable = result;
                this.table.columns = [...result, 'fileSizeBytes', 'download/delete'];
            },
            immediate: true
        }
    }
}
</script>

<style scoped>
.tableButton {
    height: 29px !important;
    width: 29px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableButton:not(:hover) {
    background-color: #131313 !important;
}

.tableButtonContainer {
    margin: -12px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    transform: translateY(6px)
}
</style>
